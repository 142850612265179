<template>
  <div class="container home">
    <div class="loginBox">
      <img src="@/assets/images/logo.png" alt="logo" class="logo" />
      <h3>로그인</h3>
      <div class="form">
        <input
          type="number"
          v-model="mobileNo"
          placeholder="휴대전화번호를 입력하세요"
        />
        <div class="password">
          <input
            type="password"
            v-model="password"
            @keyup.enter="submit"
            placeholder="비밀번호를 입력하세요"
          />
        </div>
        <div v-if="error" class="error">
          {{
            error == "mobileNo"
              ? "휴대전화번호를 입력하세요"
              : error == "password"
              ? "비밀번호를 입력하세요"
              : error == "fail"
              ? errorMsg
              : ""
          }}
        </div>
        <button class="point full" @click="submit">로그인</button>
      </div>
      <aside class="flexB">
        <el-checkbox v-model="autoLogin" ref="agreeCheck"
          >로그인 상태 유지</el-checkbox
        >

        <router-link to="/changePassword">비밀번호찾기</router-link>
      </aside>
      <hr size="1" color="#eeeeee" />
      <router-link to="/join">
        <button class="line full">휴대전화번호로 회원가입</button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { login, updateUserApptokenWithUserId } from "@/api/index";

export default {
  mixins: [format],
  data() {
    return {
      mobileNo: "",
      password: "",
      error: "",
      errorMsg: "",
      autoLogin: true,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);
    this.$store.dispatch("SET_MENU_VISIBLE", false);
  },

  methods: {
    submit() {
      if (this.mobileNo == "") {
        this.error = "mobileNo";
        return;
      } else if (this.password == "") {
        this.error = "password";
        return;
      }
      this.error = "";
      let data = {
        mobileNo: this.mobileNo,
        password: this.password,
        autoLogin: this.autoLogin,
      };
      login(data).then((res) => {
        if (res.data.status == 200) {
          let result = {
            accesstoken: res.data.accesstoken,
            userType: res.data.userInfo.userType,
          };
          this.$store.dispatch("LOGIN", result);
          // this.$store.dispatch("getAccountInfo");
          this.getUserApptoken(res.data.userInfo._id);
          if (res.data.userInfo.userType == "EXAMINER") {
            document.location.href = "/examinerHome";
          } else {
            document.location.href = "/home";
          }
        } else {
          this.error = "fail";
          this.errorMsg = res.data.message;
        }
      });
    },
    getUserApptoken(_id) {
      let userId = _id;
      const mobileAppToken = localStorage.getItem("appToken");
      if (this.apptoken == "undefined" || this.apptoken == undefined) {
        this.updateUserToken(mobileAppToken, userId);
      } else {
        if (this.apptoken == mobileAppToken) {
          console.log("동일한 토큰 정보");
        } else {
          console.log("동일하지 않은 토큰 정보");
          this.updateUserToken(mobileAppToken, userId);
        }
      }
    },
    updateUserToken(mobileAppToken, userId) {
      let data = {
        appToken: mobileAppToken,
        userId: userId,
      };
      updateUserApptokenWithUserId(data).then((res) => {
        if (res.data.status == 200) {
          console.log("토큰정보 변경 성공");
        } else {
          console.log("토큰정보 변경 실패");
        }
      });
    },
  },
};
</script>
